import PropTypes from 'prop-types'
import { createContext, useEffect, useReducer } from 'react'

// third-party
import {
    onAuthStateChanged,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut,
    sendPasswordResetEmail
} from 'firebase/auth'

// action - state management
import { LOGIN, LOGOUT } from 'store/actions/accountActions'
import accountReducer from 'store/reducers/accountReducer'

// project imports
import Loader from 'ui-component/Loader'
import useDatabase from 'hooks/useDatabase'
import { auth } from 'firebase/firebase'
import { observeMerchantUser, observePromoter } from 'firebase/userapi'
// import { observe } from 'react-intersection-observer'

// const
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
}

// ==============================|| FIREBASE AUTH CONTEXT & PROVIDER ||============================== //

const FirebaseAuthContext = createContext(null)

export const FirebaseAuthProvider = ({ children }) => {
    const { setMerchantUser, setPromoter } = useDatabase()

    const [state, dispatch] = useReducer(accountReducer, initialState)

    useEffect(
        () =>
            onAuthStateChanged(auth, (user) => {
                console.log('onAuthStateChanged', user)
                if (user) {
                    // observe data from database for merchant user OR promoter
                    observeMerchantUser(user.uid, (merchantUser) => {
                        if (merchantUser?.id) {
                            setMerchantUser(merchantUser)
                        } else {
                            observePromoter(user.uid, (promoter) => {
                                setPromoter(promoter)
                            })
                        }
                    })
                    // update state for account
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: {
                                id: user.uid,
                                email: user.email,
                                name: user.displayName || 'John Doe'
                            }
                        }
                    })
                } else {
                    dispatch({
                        type: LOGOUT
                    })
                }
            }),
        [dispatch]
    )

    const firebaseEmailPasswordSignIn = (email, password) => signInWithEmailAndPassword(auth, email, password)

    // const firebaseGoogleSignIn = () => {
    //     const provider = new firebase.auth.GoogleAuthProvider();

    //     return firebase.auth().signInWithPopup(provider);
    // };

    const firebaseRegister = async (email, password) => createUserWithEmailAndPassword(auth, email, password)

    const logout = () => signOut(auth)

    const resetPassword = async (email) => {
        await sendPasswordResetEmail(auth, email)
    }

    const updateProfile = () => {}
    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />
    }

    return (
        <FirebaseAuthContext.Provider
            value={{
                ...state,
                firebaseRegister,
                firebaseEmailPasswordSignIn,
                login: () => {},
                // firebaseGoogleSignIn,
                logout,
                resetPassword,
                updateProfile
            }}
        >
            {children}
        </FirebaseAuthContext.Provider>
    )
}

FirebaseAuthProvider.propTypes = {
    children: PropTypes.node
}

export default FirebaseAuthContext
