import { memo } from 'react';

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import useDatabase from 'hooks/useDatabase'


// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const { merchantUser, promoter } = useDatabase()
    const navItems = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                if ((merchantUser?.id) || (promoter?.id && item.id === 'account')) {
                    return <NavGroup key={item.id} item={item} />;
                }
                else {
                    break
                }
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default memo(MenuList);
