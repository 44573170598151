import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getDatabase } from 'firebase/database'
import { getFunctions } from 'firebase/functions'
import { getStorage } from 'firebase/storage'
import { FIREBASE_API } from 'config'

// initialize firebase
const firebaseApp = initializeApp(FIREBASE_API)

// getAuth
const auth = getAuth(firebaseApp)

// getDatabase
const database = getDatabase(firebaseApp)

// getFunctions
const cloudfunctions = getFunctions(firebaseApp)

// getStorage
const storage = getStorage(firebaseApp)

export { auth, database, cloudfunctions, storage }
