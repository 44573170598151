// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';
import GradeIcon from '@mui/icons-material/GradeOutlined'
import DescriptionIcon from '@mui/icons-material/DescriptionOutlined'

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: <FormattedMessage id="dashboard" />,
    type: 'group',
    children: [
        {
            id: 'manage-incentives',
            title: <FormattedMessage id="manage-incentives" />,
            type: 'collapse',
            url: '/manage-incentives',
            icon: GradeIcon,
            breadcrumbs: false
        },
        {
            id: 'update-merchant-details',
            title: <FormattedMessage id="update-merchant-details" />,
            type: 'collapse',
            url: '/update-merchant-details',
            icon: DescriptionIcon,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
