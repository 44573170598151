import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
// import { Update } from '@mui/icons-material';

// dashboard routing
const UpdateMerchantDetails = Loadable(lazy(() => import('views/dashboard/UpdateMerchantDetails')))
const ManageIncentives = Loadable(lazy(() => import('views/dashboard/ManageIncentives')))

// account routing
const ConnectWithStripe = Loadable(lazy(() => import('views/account/ConnectWithStripe')))
const ReviewActivity = Loadable(lazy(() => import('views/account/ReviewActivity')))

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/update-merchant-details/:id',
            element: <UpdateMerchantDetails />
        },
        {
            path: '/manage-incentives/:id',
            element: <ManageIncentives />
        },
        {
            path: '/connect-with-stripe',
            element: <ConnectWithStripe />
        },
        {
            path: '/review-activity/:id',
            element: <ReviewActivity />
        }
    ]
};

export default MainRoutes;
