import { useContext } from 'react'

// user provider
import FirebaseDatabaseContext from 'contexts/FirebaseDatabaseContext'

// ==============================|| USER HOOKS ||============================== //

const useDatabase = () => {
    const context = useContext(FirebaseDatabaseContext)

    if (!context) throw new Error('context must be use inside provider')

    return context
}

export default useDatabase