// third-party
import { FormattedMessage } from 'react-intl';
import ArticleIcon from '@mui/icons-material/ArticleOutlined'

// assets
import { IconBrandChrome, IconHelp, IconSitemap } from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconSitemap
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'privacy-policy',
            title: <FormattedMessage id="privacy-policy" />,
            type: 'item',
            icon: ArticleIcon,
            url: '/pages/privacy-policy',
            target: true
        },
        {
            id: 'terms-of-service',
            title: <FormattedMessage id="terms-of-service" />,
            type: 'item',
            icon: ArticleIcon,
            url: '/pages/terms-of-service',
            target: true
        }
    ]
};

export default other;
