import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// project imports
import useAuth from 'hooks/useAuth'
import useDatabase from 'hooks/useDatabase'
import { DASHBOARD_PATH, PREAPPROVE_PATH } from 'config'

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }) => {
    const { isLoggedIn } = useAuth()
    const { merchantUser, promoter } = useDatabase()
    // const { isApproved }
    const navigate = useNavigate()

    useEffect(() => {
        const merchantIDs = merchantUser.merchantIDs ? merchantUser.merchantIDs : []
        if (isLoggedIn && merchantUser.id && (merchantIDs.length > 0)) {
            console.log('navigate to dashboard')
            navigate(DASHBOARD_PATH, { replace: true })
        }
        else if (isLoggedIn && promoter.id && promoter.merchantID) {
            console.log('navigate to promoter dashboard')
            navigate(DASHBOARD_PATH, { replace: true })
        }
        else if (isLoggedIn) {
            console.log('navigate to preapprove')
            navigate(PREAPPROVE_PATH)
        }
    }, [isLoggedIn, navigate, merchantUser, promoter])

    return children
}

GuestGuard.propTypes = {
    children: PropTypes.node
}

export default GuestGuard
