// action - state management
import { SET, UPDATE, REMOVE } from '../actions/merchantsActions'

// ==============================|| MERCHANTS-PRIVATE-DATA REDUCER ||============================== //

const initialState = []

const merchantsPrivateDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET: {
            const merchantsPrivateData = action.payload
            return merchantsPrivateData
        }
        case UPDATE: {
            const merchantPrivateDataToUpdate = action.payload
            const newMerchantsPrivateDataArray = state.filter((merchantPrivateData) => (merchantPrivateData.id !== merchantPrivateDataToUpdate.id))
            newMerchantsPrivateDataArray.push(merchantPrivateDataToUpdate)
            return newMerchantsPrivateDataArray
        }
        case REMOVE: {
            const merchantPrivateDataToRemoveID = action.payload
            const newMerchantsPrivateDataArray = state.filter((merchantPrivateData) => (merchantPrivateData.id !== merchantPrivateDataToRemoveID))
            return newMerchantsPrivateDataArray
        }
        default: {
            return { ...state }
        }
    }
}

export default merchantsPrivateDataReducer
