// third-party
import { FormattedMessage } from 'react-intl'

// assets
import AccountBalanceIcon from '@mui/icons-material/AccountBalanceOutlined'
import AssessmentIcon from '@mui/icons-material/Assessment'

// ==============================|| ACCOUNT MENU ITEMS ||============================== //

const account = {
    id: 'account',
    title: <FormattedMessage id="account"/>,
    type: 'group',
    children: [
        {
            id: 'connect-with-stripe',
            title: <FormattedMessage id="connect-with-stripe"/>,
            type: 'item',
            url: 'connect-with-stripe',
            icon: AccountBalanceIcon,
            breadcrumbs: false
        },
        {
            id: 'review-activity',
            title: <FormattedMessage id="review-activity" />,
            type: 'collapse',
            url: '/review-activity',
            icon: AssessmentIcon,
            breadcrumbs: false
        }
    ]
}

export default account