// routing
import Routes from 'routes'

// project imports
import Locales from 'ui-component/Locales'
import NavigationScroll from 'layout/NavigationScroll'
import RTLLayout from 'ui-component/RTLLayout'
import Snackbar from 'ui-component/extended/Snackbar'
import ThemeCustomization from 'themes'

// auth provider
import { FirebaseAuthProvider as AuthProvider } from 'contexts/FirebaseAuthContext'
import { FirebaseDatabaseProvider } from 'contexts/FirebaseDatabaseContext'

// ==============================|| APP ||============================== //

const App = () => (
    <ThemeCustomization>
        {/* RTL layout */}
        <RTLLayout>
            <Locales>
                <NavigationScroll>
                    <FirebaseDatabaseProvider>
                        <AuthProvider>
                            <>
                                <Routes />
                                <Snackbar />
                            </>
                        </AuthProvider>
                    </FirebaseDatabaseProvider>
                </NavigationScroll>
            </Locales>
        </RTLLayout>
    </ThemeCustomization>
)

export default App
