// third-party
import { set, ref, onValue, off } from 'firebase/database'

// project imports
import { database } from 'firebase/firebase'

export const observeMerchantUser = (uid, callback) => {
    const merchantUserRef = ref(database, `merchantUsers/${uid}`)

    // detach listener first in event method is called more than once
    off(merchantUserRef, 'value')

    onValue(merchantUserRef, (snapshot) => {
        if (snapshot.exists()) {
            const snapshotResults = snapshot.val()
            const merchantUser = {
                ...snapshotResults,
                id: snapshot.key
            }
            // overwrite .merchantIDs property of object with an array containing each merchantID
            const merchantIDsObject = snapshotResults.merchantIDs ? snapshotResults.merchantIDs : {}
            let merchantIDsArray = Object.keys(merchantIDsObject)
            merchantUser.merchantIDs = merchantIDsArray

            callback(merchantUser)
        } else {
            callback(null)
        }
    })
}

export const observePromoter = (uid, callback) => {
    const promoterRef = ref(database, `promoterPrivateData/${uid}`)

    // detach listener first in event method is called more than once
    off(promoterRef, 'value')

    onValue(promoterRef, (snapshot) => {
        console.log('snapshot', snapshot)
        if (snapshot.exists()) {
            const snapshotResults = snapshot.val()
            const promoter = {
                ...snapshotResults,
                id: snapshot.key
            }

            callback(promoter)
        }
    })
}

export const createPreApprovedMerchantUser = (uid, firstName, lastName, email, callback) => {
    set(ref(database, `preApprovedMerchantUsers/${uid}`),
        {
            first_name: firstName,
            last_name: lastName,
            email: email
        }
    ).then(() => {
        callback()
    })
}

export const createPreApprovedPromoter = (uid, firstName, lastName, email, callback) => {
    set(ref(database, `preApprovedPromoters/${uid}`),
        {
            first_name: firstName,
            last_name: lastName,
            email: email
        }
    ).then(() => {
        callback()
    })
}