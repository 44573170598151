import dashboard from './dashboard'
import account from './account'
import other from './other'

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, account, other]
}

export default menuItems
