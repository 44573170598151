// import { Link as RouterLink } from 'react-router-dom';

// material-ui
// import { Link } from '@mui/material';

// project imports
// import { DASHBOARD_PATH } from 'config';
import Logo from 'ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <Logo />
)

// const LogoSection = () => (
//     <Link component={RouterLink} to={DASHBOARD_PATH}>
//         <Logo />
//     </Link>
// )

export default LogoSection;
