// action - state management
import { SET, UPDATE, REMOVE } from '../actions/merchantsActions'

// ==============================|| MERCHANTS REDUCER ||============================== //

const initialState = []

const merchantsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET: {
            const merchants = action.payload
            return merchants
        }
        case UPDATE: {
            const merchantToUpdate = action.payload
            const newMerchantsArray = state.filter((merchant) => (merchant.id !== merchantToUpdate.id))
            newMerchantsArray.push(merchantToUpdate)
            return newMerchantsArray
        }
        case REMOVE: {
            const merchantToRemoveID = action.payload
            const newMerchantsArray = state.filter((merchant) => (merchant.id !== merchantToRemoveID))
            return newMerchantsArray
        }
        default: {
            return { ...state }
        }
    }
}

export default merchantsReducer
